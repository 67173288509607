'use client';

import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useAppState } from '../providers/state-provider';

export default function ClientJs() {
  const {
    state: { isBrowser },
  } = useAppState();

  const [hostname, setHostname] = useState<string>();

  useEffect(() => {
    if (isBrowser && document.location.hostname) {
      setHostname(document.location.hostname);
    }
  }, [isBrowser && document.location.hostname]);

  return (
    <>
      {hostname?.endsWith('lectionary.com') && (
        <>
          <Script
            strategy='afterInteractive'
            src={`https://www.googletagmanager.com/gtag/js?id=G-XWSMS2Z6Q4`}
          />
          <Script
            id='ga-script'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-XWSMS2Z6Q4');
                  });
              `,
            }}
          />
        </>
      )}
      {!hostname?.endsWith('lectionary.com') && (
        <>
          <Script
            strategy='afterInteractive'
            src={`https://www.googletagmanager.com/gtag/js?id=G-0NLCXVSWH1`}
          />
          <Script
            id='ga-script'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-0NLCXVSWH1', {
                  page_path: window.location.pathname + window.location.search,
                  pageTitle: document.title,
                });
              `,
            }}
          />
        </>
      )}
    </>
  );
}
