'use client';

import { useEffect } from 'react';
import { useAppState } from '../providers/state-provider';

declare global {
  interface Window {
    gtag: (a: string, b: string, c: object) => void;
  }
}

export default function PageView() {
  const {
    state: { isBrowser },
  } = useAppState();

  const pathname = isBrowser ? window.location.pathname : null;
  const search = isBrowser ? window.location.search : null;

  useEffect(() => {
    if (search !== null && search !== null) {
      const originalUrl = pathname + search;

      window.gtag('config', 'G-0NLCXVSWH1', {
        pagePath: originalUrl,
        pageTitle: document.title,
      });
    }
  }, [pathname, search]);

  return null;
}
